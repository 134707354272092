import { Button, Loading } from 'semente-js'
import Page from '../../components/page'
import CampaignModal from './components/campaign-modal'
import { useCallback, useEffect, useState } from 'react'
import { Campaign } from './campaign-types'
import CampaignService from '../../services/campaign.service'
import CampaignItem from './components/campaign-item'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes/path'

const HomePage = () => {
  const [newCampaign, setNewCampaign] = useState(false)
  const [loading, setLoading] = useState(true)
  const [campaigns, setCampaigns] = useState<Campaign[]>([])
  const navigate = useNavigate()

  const loadList = useCallback(async () => {
    setLoading(true)
    const data = await CampaignService.getCampaigns()
    setCampaigns(data)
    setLoading(false)
  }, [])

  useEffect(() => {
    loadList()
  }, [loadList])

  const onCloseModal = (refresh?: boolean) => {
    setNewCampaign(false)
    if (refresh) {
      loadList()
    }
  }

  const onSelectCampaign = ({ id }: Campaign) => {
    if (id) navigate(ROUTES.campaignDetails.replace(':id', id))
  }

  return (
    <Page
      title='Campaigns'
      subtitle='Global grand challenges'
      rightContent={
        <Button
          size='sm'
          color='primary'
          layout='rounded'
          iconName='plus'
          label='Create campaign'
          className='my-auto ml-4 h-10 bg-brand-rama-500'
          onClick={() => setNewCampaign(true)}
        />
      }
    >
      {loading && (
        <div className='m-auto'>
          <Loading />
        </div>
      )}

      {campaigns?.map(item => (
        <CampaignItem key={item.id} campaign={item} onClick={() => onSelectCampaign(item)} />
      ))}

      <CampaignModal isOpen={newCampaign} onClose={onCloseModal} />
    </Page>
  )
}

export default HomePage
