import { Button, Text } from 'semente-js'
import { useAuth } from '../../contexts/auth'
import Header from '../header'
import { useNavigate } from 'react-router-dom'

type Props = {
  title: string
  subtitle?: string
  rightContent?: React.ReactNode
  showBackButton?: boolean
  children: React.ReactNode
}

const Page: React.FC<Props> = ({ title, children, subtitle, rightContent, showBackButton }) => {
  const { user, logout } = useAuth()
  const navigate = useNavigate()

  const onBack = () => {
    navigate(-1)
  }

  return (
    <div className='flex min-h-screen flex-col items-center bg-white'>
      {user && <Header user={user} onLogout={logout} />}
      <div className='mx-auto flex w-full max-w-[1024px] flex-col p-2'>
        <div className='mt-2 flex  items-center'>
          {showBackButton && (
            <Button
              size='base'
              color='primary'
              layout='circle'
              iconName='arrow-left'
              onClick={onBack}
              className='mr-4 bg-brand-rama-500 '
            />
          )}
          <div className='flex flex-1 flex-col gap-4'>
            <Text as='h3' className='heading-md  text-brand-rama-600'>
              {title}
            </Text>
            {subtitle && <Text className='body-md mt-[-16px] text-brand-rama-400'>{subtitle}</Text>}
          </div>
          {rightContent}
        </div>
        <div className='my-5 border-[1px] border-solid border-brand-rama-500' />
        {children}
      </div>
    </div>
  )
}

export default Page
