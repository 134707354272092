import { useCallback, useEffect, useState } from 'react'
import Page from '../../components/page'
import CampaignService from '../../services/campaign.service'
import { useParams } from 'react-router-dom'
import { Campaign, Chapter, Story } from '../home/campaign-types'
import { Button, Input, Loading, Modal, Text } from 'semente-js'
import CampaignDetails from './campaign-details'
import StoryModal from './story-modal'

const CampaignPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [newHistory, setNewHistory] = useState(false)
  const [storyToShow, setStoryToShow] = useState<Story>()
  const [campaign, setCampaign] = useState<Campaign>()
  const { id } = useParams<{ id: string }>()

  const getCampaign = useCallback(async () => {
    if (id) {
      setIsLoading(true)
      setCampaign(await CampaignService.getCampaignById(id))
      setIsLoading(false)
    }
  }, [id])

  useEffect(() => {
    getCampaign()
  }, [getCampaign])

  const onClose = (refresh?: boolean) => {
    if (refresh) getCampaign()
    setNewHistory(false)
  }

  const updateChapters = (indexToUpdate: number, newChapter: Chapter) => {
    if (storyToShow?.ai_generated?.chapters)
      setStoryToShow({
        ...storyToShow,
        ai_generated: {
          chapters: storyToShow?.ai_generated?.chapters.map((chapter, index) =>
            index === indexToUpdate ? newChapter : chapter
          )
        }
      })
  }

  const updateStory = async () => {
    if (id && storyToShow?.id) {
      setIsLoading(true)
      await CampaignService.updateStory(id || '', storyToShow?.id || '', storyToShow)
      setStoryToShow(undefined)
      await getCampaign()
    }
  }

  return (
    <Page
      title={campaign?.ai_generated?.title || 'Generating campaign...'}
      subtitle={campaign?.ai_generated?.excerpt}
      showBackButton
      rightContent={
        !campaign?.ai_generated?.title && (
          <Button
            size='sm'
            color='primary'
            layout='rounded'
            iconName='redo'
            label='Reload'
            className='my-auto ml-4 bg-brand-rama-500'
            onClick={getCampaign}
          />
        )
      }
    >
      {isLoading || !campaign ? (
        <div className='m-auto'>
          <Loading />
        </div>
      ) : (
        <>
          <CampaignDetails campaign={campaign} refreshCampaign={getCampaign} />
          {campaign.status === 'complete' && (
            <>
              <div className='mt-5 flex items-center justify-between'>
                <Text as='h3' className='heading-sm  text-brand-rama-600'>
                  {'Story'}
                </Text>

                {!campaign.story && (
                  <Button
                    size='sm'
                    color='primary'
                    layout='rounded'
                    iconName='plus'
                    label='New story'
                    className='my-auto ml-4 cursor-pointer bg-brand-rama-500'
                    onClick={() => setNewHistory(true)}
                  />
                )}
              </div>
              <div className='mb-4 mt-2 border-[1px] border-solid border-brand-rama-500' />

              {!campaign.story && (
                <div className='m-auto my-5'>
                  <Text className='text-gray-500'>{'This campaign does not have stories'}</Text>
                </div>
              )}
              <div
                onClick={() => setStoryToShow(campaign.story)}
                className='flex cursor-pointer flex-col gap-2 rounded-md border-[1px] p-4 text-sm shadow-sm transition-all hover:shadow-lg'
              >
                <div className=''>
                  <div className='block font-bold'>Name</div>
                  {campaign.story?.name}
                </div>
                <div className=''>
                  <div className='block font-bold'>Genre</div>
                  {campaign.story?.genre}
                </div>
                <div className=''>
                  <div className='block font-bold'>Chapters</div>
                  {campaign.story?.chapters}
                </div>
                <div className=''>
                  <div className='block font-bold'>Threats</div>

                  {campaign.story?.story_threat.map(item => item.threat?.name).join(', ')}
                </div>

                <div className=''>
                  <div className='block font-bold'>Protagonist</div>
                  {campaign.story?.protagonist?.name}
                </div>
                <div className=''>
                  <div className='block font-bold'>Other Characters</div>

                  {campaign.story?.characters?.map(item => item?.name).join(', ') || '-'}
                </div>
              </div>
            </>
          )}

          <div className='h-20' />

          <Modal isOpen={!!storyToShow} ariaLabelledby='Story' maxWidth='1024px' maxHeight='95vh'>
            <div className='flex w-full flex-col'>
              <div className='mb-4 text-3xl font-semibold'>Story: {storyToShow?.name}</div>
              <hr />

              <ChapterFormList
                chapters={storyToShow?.ai_generated?.chapters || []}
                updateChapters={updateChapters}
              />

              <hr className='mb-5' />

              <div className='ml-auto mt-auto flex w-full  gap-4'>
                <Button
                  label='Close'
                  className='w-fit  bg-gray-500 hover:bg-gray-400'
                  onClick={() => setStoryToShow(undefined)}
                />

                <Button
                  label='Save'
                  className='ml-auto w-[200px] bg-brand-rama-500 hover:bg-brand-rama-600'
                  onClick={updateStory}
                />
              </div>
            </div>
          </Modal>

          <StoryModal
            isOpen={newHistory}
            duration={campaign.duration}
            campaign_id={campaign.id || ''}
            onClose={onClose}
          />
        </>
      )}
    </Page>
  )
}

const ChapterFormList: React.FC<{
  chapters: Chapter[]
  updateChapters: (indexToUpdate: number, newChapter: Chapter) => void
}> = ({ chapters, updateChapters }) => {
  const [items] = useState<Chapter[]>(chapters)

  return (
    <div className='flex w-full flex-col gap-4 overflow-x-hidden overflow-y-scroll py-4'>
      {items.map((chapter, index) => (
        <ChapterForm
          chapter={chapter}
          chapterNumber={index + 1}
          key={index}
          onChange={values => updateChapters(index, values)}
        />
      ))}
    </div>
  )
}

const ChapterForm: React.FC<{
  chapter: Chapter
  chapterNumber: number
  onChange: (value: Chapter) => void
}> = ({ chapter, onChange, chapterNumber }) => {
  const [title, setTitle] = useState(chapter.title)
  const [content, setContent] = useState(chapter.content)

  return (
    <div key={chapter.title} className='flex w-full flex-col'>
      <div className='font-bold'>Chapter {chapterNumber}.</div>
      <Input
        value={title}
        onChange={e => {
          setTitle(e.target.value)
          onChange({ title: e.target.value, content })
        }}
        id={chapterNumber + '-title'}
        placeholder={'Title'}
        className='mb-2 w-full'
      />
      <Input
        isTextArea={true}
        onChange={e => {
          setContent(e.target.value)
          onChange({ title, content: e.target.value })
        }}
        value={content}
        id={chapterNumber + '-description'}
        placeholder={'Content'}
      />
    </div>
  )
}

export default CampaignPage
