import { Campaign } from '../campaign-types'

type Props = { campaign: Campaign; onClick: () => void }

const CampaignItem: React.FC<Props> = ({ campaign, onClick }) => {
  const getTitle = () => {
    switch (campaign.status) {
      case 'pending':
        return 'Generating campaign...'
      case 'fail':
        return 'Error.'
      default:
        return campaign.ai_generated?.title
    }
  }

  return (
    <div
      className='mb-5 cursor-pointer rounded-md border-[1px] border-solid p-4 shadow-sm transition-all hover:shadow-md'
      onClick={onClick}
    >
      {campaign.status === 'done' && (
        <div className='mb-2 w-fit rounded-xl bg-gray-200 px-2 py-1 text-sm text-gray-800'>
          Draft
        </div>
      )}
      {campaign.status === 'complete' && (
        <div className='mb-2 w-fit rounded-xl bg-green-200 px-2 py-1 text-sm text-green-800'>
          Generated
        </div>
      )}
      <h3 className='heading-sm mb-4 truncate'>{getTitle()}</h3>
      <div className='text-right text-sm text-gray-500'>
        {new Date(campaign?.createdAt || '').toLocaleString()}
      </div>
    </div>
  )
}

export default CampaignItem
