import { Skill } from '../pages/home/campaign-types'
import api from './api.service'

class SkillService {
  static async getSkills() {
    return (await api.get<Skill[]>('/skill')).data
  }
}

export default SkillService
