import { Threat } from '../pages/home/campaign-types'
import api from './api.service'

class ThreatService {
  static async getThreats() {
    return (await api.get<Threat[]>('/threat')).data
  }
}

export default ThreatService
