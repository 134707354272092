import { Campaign, Story } from '../pages/home/campaign-types'
import api from './api.service'

class CampaignService {
  static async getCampaigns() {
    return (await api.get<Campaign[]>('/campaign')).data
  }

  static async getCampaignById(id: string) {
    return (await api.get<Campaign>(`/campaign/${id}`)).data
  }

  static async generateAgin(id: string) {
    return (await api.post<Campaign>(`/campaign/${id}/generate`)).data
  }

  static async createCampaign(newCampaign: Campaign) {
    return (await api.post<Campaign>('/campaign', newCampaign)).data
  }

  static async createStory(campaign_id: string, newStory: Story) {
    return (await api.post<Campaign>(`/campaign/${campaign_id}/story`, newStory)).data
  }

  static async updateStory(campaign_id: string, story_id: string, values: Story) {
    return (await api.put<Campaign>(`/campaign/${campaign_id}/story/${story_id}`, values)).data
  }

  static async updateCampaign(campaign_id: string, newValues: Partial<Campaign>) {
    return (await api.put<Campaign>(`/campaign/${campaign_id}`, newValues)).data
  }

  static async completeCampaign(campaign_id: string) {
    return (await api.post<Campaign>(`/campaign/${campaign_id}/complete`)).data
  }
}

export default CampaignService
