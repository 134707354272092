import {
  Button,
  Input,
  Loading,
  Modal,
  Select,
  SelectItem,
  Text,
  useToastContext
} from 'semente-js'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Character, Story } from '../home/campaign-types'
import CampaignService from '../../services/campaign.service'
import ThreatService from '../../services/threat.service'

type StoryFormType = Omit<Story, 'story_threat'> & {
  story_threat: string[]
  protagonist_name: string
  protagonist_personality: string
  protagonist_role: string
}

const initialStoryValues: StoryFormType = {
  name: '',
  genre: '',
  chapters: '',
  story_threat: [],
  protagonist_name: '',
  protagonist_role: '',
  protagonist_personality: ''
}

const initialCharacterValues: Character = {
  name: '',
  personality: '',
  role: ''
}

const validationStorySchema = Yup.object({
  name: Yup.string().required(),
  genre: Yup.string().required(),
  story_threat: Yup.array().min(1),
  chapters: Yup.number().required(),

  protagonist_name: Yup.string().required(),
  protagonist_personality: Yup.string().required(),
  protagonist_role: Yup.string().required()
})

const validationCharacterSchema = Yup.object({
  name: Yup.string().required(),
  personality: Yup.string().required(),
  role: Yup.string().required()
})

const PERSONALITIES = [
  { label: 'Amicable', value: 'Amicable' },
  { label: 'Competitive', value: 'Competitive' },
  { label: 'Angry', value: 'Angry' },
  { label: 'Revolted', value: 'Revolted' },
  { label: 'Silly', value: 'Silly' },
  { label: 'Funny', value: 'Funny' },
  { label: 'Inspiring', value: 'Inspiring' }
]

const STORY_GENRE = [
  {
    label: 'Post Apocalyptic futuristic sci-fi',
    value: 'Post Apocalyptic futuristic sci-fi'
  },
  {
    label: 'Futuristic thriller',
    value: 'Futuristic thriller'
  },
  {
    label: 'Present day realistic tale',
    value: 'Present day realistic tale'
  },
  {
    label: 'Present day drama',
    value: 'Present day drama'
  },
  {
    label: 'Medieval high fantasy',
    value: 'Medieval high fantasy'
  },
  {
    label: "Children's tale",
    value: "Children's tale"
  }
]

type Props = {
  isOpen: boolean
  campaign_id: string
  duration: string
  onClose: (refresh?: boolean) => void
}

const StoryModal: React.FC<Props> = ({ campaign_id, duration, isOpen, onClose }) => {
  const { toast } = useToastContext()
  const [characters, setCharacters] = useState<Character[]>([])
  const [showAddCharacter, setShowAddCharacter] = useState(false)

  const [threats, setThreats] = useState<SelectItem[]>([])

  const storyFormik = useFormik({
    initialValues: { ...initialStoryValues, chapters: duration },
    validationSchema: validationStorySchema,
    onSubmit: async ({
      protagonist_name,
      protagonist_personality,
      protagonist_role,
      ...values
    }: StoryFormType) => {
      try {
        await CampaignService.createStory(campaign_id, {
          ...values,
          characters,
          protagonist: {
            name: protagonist_name,
            personality: protagonist_personality,
            role: protagonist_role
          },
          story_threat: values.story_threat.map(threat_id => ({ threat_id }))
        })
        onClose(true)
      } catch (err) {
        toast.error((err as Error).message || 'Something went wrong')
      }
    }
  })

  const characterFormik = useFormik({
    initialValues: initialCharacterValues,
    validationSchema: validationCharacterSchema,
    onSubmit: async (newCharacter: Character) => {
      try {
        setCharacters(current => [...current, newCharacter])
        setShowAddCharacter(false)
        characterFormik.resetForm()
      } catch (err) {
        toast.error((err as Error).message || 'Something went wrong')
      }
    }
  })

  useEffect(() => {
    if (isOpen) storyFormik.resetForm()
    ThreatService.getThreats().then(items => {
      setThreats(items.map(skill => ({ value: skill.id, label: skill.name })))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} ariaLabelledby='Campaign Modal' maxWidth='1024px' maxHeight='95vh'>
      {storyFormik.isSubmitting && (
        <div className='fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-white bg-opacity-50'>
          <Loading />
        </div>
      )}
      {showAddCharacter ? (
        <div className='flex w-full flex-col'>
          <Text className='heading-md '>New character</Text>

          <hr className='mt-5' />

          <div className='max-h-full overflow-y-scroll px-2 py-5'>
            <div className='mb-4'>
              <label className='font-bold'>Name</label>
              <Input
                id='name'
                name='name'
                className='mt-2'
                placeholder=''
                onBlur={characterFormik.handleBlur}
                value={characterFormik.values.name}
                onChange={characterFormik.handleChange}
                errorMessage={
                  characterFormik.submitCount > 0 ? characterFormik.errors.name : undefined
                }
              />
            </div>

            <div className='mb-4'>
              <label className='font-bold'>Personality</label>
              <Select
                layout='line'
                items={PERSONALITIES}
                isMultiple={false}
                placeholder='Select one'
                onChange={value => {
                  characterFormik.setFieldValue('personality', value[0])
                }}
                className={`mt-2 w-full ${characterFormik.submitCount > 0 && characterFormik.errors.personality && 'border-[2px] border-red-600'}`}
              />
            </div>

            <div className='mb-4'>
              <label className='font-bold'>Role in the story</label>
              <Input
                id='role'
                name='role'
                className='mt-2'
                placeholder=''
                onBlur={characterFormik.handleBlur}
                value={characterFormik.values.role}
                onChange={characterFormik.handleChange}
                errorMessage={
                  characterFormik.submitCount > 0 ? characterFormik.errors.role : undefined
                }
              />
            </div>
          </div>
          <hr className='mb-5' />

          <div className='ml-auto mt-auto flex w-full max-w-[300px] gap-4'>
            <Button
              label='Cancel'
              className='flex-1 bg-gray-500 hover:bg-gray-400'
              onClick={() => setShowAddCharacter(false)}
            />

            <Button
              label='Add character'
              onClick={() => characterFormik.submitForm()}
              className='flex-1 bg-brand-rama-500 hover:bg-brand-rama-400'
            />
          </div>
        </div>
      ) : (
        <div className='flex w-full flex-col'>
          <Text className='heading-md '>New story</Text>

          <hr className='mt-5' />
          <div className='max-h-full overflow-y-scroll px-2 py-5'>
            <div className='mb-4'>
              <label className='font-bold'>Name</label>
              <Input
                id='name'
                name='name'
                className='mt-2'
                placeholder=''
                onBlur={storyFormik.handleBlur}
                value={storyFormik.values.name}
                onChange={storyFormik.handleChange}
                errorMessage={storyFormik.submitCount > 0 ? storyFormik.errors.name : undefined}
              />
            </div>

            <div className='mb-4'>
              <label className='font-bold'>Chapters</label>
              <Input
                type='number'
                id='chapters'
                name='chapters'
                className='mt-2'
                placeholder=''
                onBlur={storyFormik.handleBlur}
                value={storyFormik.values.chapters}
                onChange={storyFormik.handleChange}
                errorMessage={storyFormik.submitCount > 0 ? storyFormik.errors.chapters : undefined}
              />
            </div>

            <div className='mb-4'>
              <label className='font-bold'>Genre</label>
              <Select
                layout='line'
                items={STORY_GENRE}
                isMultiple={false}
                placeholder='Select one'
                onChange={value => {
                  storyFormik.setFieldValue('genre', value[0])
                }}
                className={`mt-2 w-full ${storyFormik.submitCount > 0 && storyFormik.errors.genre && 'border-[2px] border-red-600'}`}
              />
            </div>

            <div className='mb-4'>
              <label className='font-bold'>Threats</label>
              <Select
                layout='line'
                items={threats}
                isMultiple={true}
                placeholder='Threats'
                value={storyFormik.values.story_threat}
                onChange={values => {
                  storyFormik.setFieldValue('story_threat', values)
                }}
                className={`mt-2 w-full ${storyFormik.submitCount > 0 && storyFormik.errors.story_threat && 'border-[2px] border-red-600'}`}
              />
            </div>

            <div className='rounded-md border-[1px] p-2'>
              <label className='text-lg font-bold'>Protagonist</label>
              <div className='mb-4'>
                <label className='font-bold'>Name</label>
                <Input
                  id='protagonist_name'
                  name='protagonist_name'
                  className='mt-2'
                  placeholder=''
                  onBlur={storyFormik.handleBlur}
                  value={storyFormik.values.protagonist_name}
                  onChange={storyFormik.handleChange}
                  errorMessage={
                    storyFormik.submitCount > 0 ? storyFormik.errors.protagonist_name : undefined
                  }
                />
              </div>

              <div className='mb-4'>
                <label className='font-bold'>Personality</label>
                <Select
                  layout='line'
                  items={PERSONALITIES}
                  isMultiple={false}
                  placeholder='Select one'
                  onChange={value => {
                    storyFormik.setFieldValue('protagonist_personality', value[0])
                  }}
                  className={`mt-2 w-full ${storyFormik.submitCount > 0 && storyFormik.errors.protagonist_personality && 'border-[2px] border-red-600'}`}
                />
              </div>

              <div className='mb-4'>
                <label className='font-bold'>Role in the story</label>
                <Input
                  id='protagonist_role'
                  name='protagonist_role'
                  className='mt-2'
                  placeholder=''
                  onBlur={storyFormik.handleBlur}
                  value={storyFormik.values.protagonist_role}
                  onChange={storyFormik.handleChange}
                  errorMessage={
                    storyFormik.submitCount > 0 ? storyFormik.errors.protagonist_role : undefined
                  }
                />
              </div>
            </div>

            <div className='min-h-[220px]'>
              <div className='mb-2 flex items-center justify-between border-b-[1px] py-2 text-xl font-bold'>
                Other characters
                <Button
                  layout='rounded'
                  label='Add character'
                  onClick={() => setShowAddCharacter(true)}
                  className='bg-brand-rama-500 hover:bg-brand-rama-400'
                />
              </div>
              {characters.length === 0 && <div>This story does not have other characters</div>}
              <div className='grid grid-cols-3 gap-4'>
                {characters.map(item => (
                  <div key={item.name} className='rounded-md border-[1px] p-2 text-sm'>
                    <div>
                      <div className='font-bold'>Name</div>
                      {item.name}
                    </div>
                    <div>
                      <div className='font-bold'>Personality</div>
                      {item.personality}
                    </div>
                    <div>
                      <div className='font-bold'>Role</div>
                      {item.role}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <hr className='mb-5' />

          <div className='ml-auto mt-auto flex w-full max-w-[300px] gap-4'>
            <Button
              label='Cancel'
              className='flex-1 bg-gray-500 hover:bg-gray-400'
              onClick={() => onClose(false)}
            />

            <Button
              label='Save'
              onClick={() => storyFormik.submitForm()}
              className='flex-1 bg-brand-rama-500 hover:bg-brand-rama-400'
            />
          </div>
        </div>
      )}
    </Modal>
  )
}

export default StoryModal
