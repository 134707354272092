import { useState } from 'react'
import { Campaign, Plan, Result } from '../home/campaign-types'
import EditPlanModal from './edit-plan-modal'
import CampaignService from '../../services/campaign.service'
import { Button, Loading, Modal } from 'semente-js'

type Props = { campaign: Campaign; refreshCampaign: () => Promise<void> }

const CampaignDetails: React.FC<Props> = ({ campaign, refreshCampaign }) => {
  // const [seeMore, setSeeMore] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [planToEdit, setPlanToEdit] = useState<{ index: number; plan: Plan }>()
  const [resultToShow, setResultToShow] = useState<Result>()

  const updatePlan = async (values: Plan) => {
    if (campaign.id && campaign.ai_generated?.plan) {
      await CampaignService.updateCampaign(campaign.id, {
        ai_generated: {
          ...campaign.ai_generated,
          plan: campaign.ai_generated.plan?.map((item, index) =>
            index === planToEdit?.index ? values : item
          )
        }
      })
    }
  }

  const completeCampaign = async () => {
    setIsLoading(true)
    try {
      if (campaign?.id) await CampaignService.completeCampaign(campaign.id)
      await refreshCampaign()
    } catch (err) {
      console.log(err)
    }
    setIsLoading(false)
  }

  return (
    <div className={`relative h-auto overflow-hidden px-4 pb-20`}>
      <div className={` flex flex-wrap gap-2 `}>
        <div className='mb-2 min-w-[300px] flex-1 text-sm'>
          <div className='block font-bold'>Duration</div>
          {campaign.duration} Weeks
        </div>
        <div className='mb-2 min-w-[300px] flex-1 text-sm'>
          <div className='block font-bold'>Frequency</div>
          {campaign.frequency} Hours/Week
        </div>
        <div className='mb-2 min-w-[300px] flex-1 text-sm'>
          <div className='block font-bold'>Devices that will be used</div>
          {campaign.devices_used}
        </div>
        <div className='mb-2 min-w-[300px] flex-1 text-sm'>
          <div className='block font-bold'>Challenge</div>
          {campaign.grand_challenge}
        </div>
        <div className='mb-2 min-w-[300px] flex-1 text-sm'>
          <div className='block font-bold'>Internet access</div>
          {campaign.internet_access}
        </div>
        <div className='mb-2 min-w-[300px] flex-1 text-sm'>
          <div className='block font-bold'>Skills</div>
          {campaign.campaign_skills.map(item => item.skill?.name).join(', ')}
        </div>

        <div className='mb-2 min-w-[300px] flex-1 text-sm'>
          <div className='block font-bold'>Goals</div>
          {campaign.goals}
        </div>

        <div className='mb-2 min-w-[300px] flex-1 text-sm'>
          <div className='block font-bold'>How will you use</div>
          {campaign.how_will_use}
        </div>

        <div className='mb-2 min-w-[300px] flex-1 text-sm'>
          <div className='block font-bold'>Badges</div>
          {campaign.badges}
        </div>

        <div className='mb-2 min-w-[300px] flex-1 text-sm'>
          <div className='block font-bold'>Explore</div>
          {campaign.explore}
        </div>

        <div className='mb-2 min-w-[300px] flex-1 text-sm'>
          <div className='block font-bold'>Imagine</div>
          {campaign.imagine}
        </div>

        <div className='mb-2 min-w-[300px] flex-1 text-sm'>
          <div className='block font-bold'>Act</div>
          {campaign.act}
        </div>

        <div className='mb-2 min-w-[300px] flex-1 text-sm'>
          <div className='block font-bold'>Communicate</div>
          {campaign.communicate}
        </div>
      </div>
      <div className={` mt-2 `}>
        <div className='mb-4 block text-xl font-bold'>Pedagogical plan</div>
        {isLoading ? (
          <Loading />
        ) : campaign.status === 'complete' ? (
          <div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
            {campaign.ai_generated?.result?.map((result, index) => (
              <div
                key={index}
                onClick={() => setResultToShow(result)}
                className='flex cursor-pointer flex-col gap-1 rounded-md border-[1px] p-4 shadow-md transition-all hover:shadow-lg'
              >
                <p className='font-semibold'>Title</p>
                <div className='mb-2'>{result.title}</div>
              </div>
            ))}
          </div>
        ) : campaign.ai_generated?.plan ? (
          <div className='flex flex-col gap-4'>
            {campaign.ai_generated?.plan?.map((plan, index) => (
              <div
                key={plan.weekName}
                className='grid grid-cols-8 gap-2 rounded-md border-[1px] px-4 py-2 shadow-md'
              >
                <p className='col-span-8 w-full font-bold'>{plan.weekName}</p>

                <div className='col-span-2 flex flex-col'>
                  <p className='font-semibold'>Mission Number</p>
                  <p>{plan.missionNumber}</p>
                </div>

                <div className='col-span-6 flex flex-col'>
                  <p className='font-semibold'>Activity</p>
                  <p>{plan.activity}</p>
                </div>

                <div className='col-span-4 flex flex-col'>
                  <p className='font-semibold'>Activity Tools</p>
                  <p>{plan.activityTools}</p>
                </div>
                <div className='col-span-4 flex flex-col'>
                  <p className='font-semibold'>Assessment Rubric</p>
                  <p>{plan.assessmentRubric}</p>
                </div>

                <div className='col-span-2 flex flex-col'>
                  <p className='font-semibold'>Badge Earned</p>
                  <p>{plan.badgeEarned}</p>
                </div>
                <div className='col-span-2 flex flex-col'>
                  <p className='font-semibold'>Skill Developed</p>
                  <p>{plan.skillDeveloped}</p>
                </div>
                <div className='col-span-2 flex flex-col'>
                  <p className='font-semibold'>Skill PowerLevel</p>
                  <p>{plan.skillPowerLevel}</p>
                </div>
                <div className='col-span-8 ml-auto mt-auto flex'>
                  <Button
                    size='sm'
                    label='Edit'
                    color='gray'
                    iconName='edit'
                    layout='rounded'
                    onClick={() => setPlanToEdit({ index, plan })}
                    className='mt-auto bg-gray-200'
                  />
                </div>
              </div>
            ))}

            {campaign.ai_generated?.plan && (
              <Button
                label='Campaign expansion'
                onClick={completeCampaign}
                className='flex-1 bg-brand-rama-500 hover:bg-brand-rama-400'
              />
            )}
          </div>
        ) : (
          <Loading />
        )}
      </div>

      {planToEdit && (
        <EditPlanModal
          onClose={async refresh => {
            setPlanToEdit(undefined)
            if (refresh) await refreshCampaign()
          }}
          onSubmit={updatePlan}
          plan={planToEdit.plan}
        />
      )}

      <Modal
        isOpen={!!resultToShow}
        ariaLabelledby=''
        onClose={() => setResultToShow(undefined)}
        maxWidth='90%'
      >
        <div className='flex flex-col gap-1 rounded-md p-2'>
          <p className='font-semibold'>Title</p>
          <div className='mb-2'>{resultToShow?.title}</div>
          <p className='font-semibold'>Excerpt</p>
          <div dangerouslySetInnerHTML={{ __html: resultToShow?.excerpt || '' }} className='mb-2' />
          <p className='font-semibold'>Content</p>
          <div dangerouslySetInnerHTML={{ __html: resultToShow?.content || '' }} />
          <Button
            label='Close'
            onClick={() => setResultToShow(undefined)}
            className='mt-5 bg-black text-white'
          />
        </div>
      </Modal>
    </div>
  )
}

export default CampaignDetails
