import { Button, ProfileImage } from 'semente-js'
import evoke from '../../assets/img/evoke.png'
import { User } from '../../types'
import { Link, useLocation } from 'react-router-dom'
import { ROUTES } from '../../routes/path'

type Props = { user: User; onLogout: () => void }

const MENU_ITEMS = [
  { label: 'Home', route: ROUTES.home },
  { label: 'Users', route: ROUTES.users }
]

const initialsFormat = (name: string, surname: string) => {
  return (name[0] + surname[0]).toUpperCase()
}

const Header: React.FC<Props> = ({ user, onLogout }) => {
  const { pathname } = useLocation()

  return (
    <header className='w-full border-b-[1px] p-2'>
      <div className='mx-auto flex max-w-[1024px] items-center px-2'>
        <img src={evoke} className='mr-5 h-6' alt='evoke logo' />
        <div className='mr-auto flex'>
          {MENU_ITEMS.map(item => (
            <Link
              to={item.route}
              className={`mr-4 rounded-md px-2 py-1 ${pathname === item.route ? 'bg-brand-rama-100 text-gray-800' : 'text-gray-600'}`}
              key={item.route}
            >
              {item.label}
            </Link>
          ))}
        </div>

        <ProfileImage initials={initialsFormat(user.name, user.surname)} />
        <div className='ml-4 flex flex-col'>
          <span className='body-md font-bold text-brand-rama-500'>{`${user.name} ${user.surname}`}</span>
          <span className='body-xs text-gray-500'>{user.email}</span>
        </div>

        <Button
          size='sm'
          label='Logout'
          color='primary'
          layout='rounded'
          iconName='signout'
          onClick={onLogout}
          className='ml-4 bg-brand-rama-500'
        />
      </div>
    </header>
  )
}

export default Header
