import { Button, Input, Loading, Modal, Text, useToastContext } from 'semente-js'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Plan } from '../home/campaign-types'

type PlanFormType = Plan

const validationPlanSchema = Yup.object({
  activity: Yup.string().required(),
  weekName: Yup.string().required(),
  badgeEarned: Yup.string().required(),
  activityTools: Yup.string().required(),
  missionNumber: Yup.string().required(),
  skillDeveloped: Yup.string().required(),
  skillPowerLevel: Yup.string().required(),
  assessmentRubric: Yup.string().required()
})

type Props = {
  plan: Plan
  onClose: (refresh?: boolean) => void
  onSubmit: (newPlan: Plan) => Promise<void>
}

const EditPlanModal: React.FC<Props> = ({ plan, onClose, onSubmit }) => {
  const { toast } = useToastContext()

  const planFormik = useFormik({
    initialValues: { ...plan },
    validationSchema: validationPlanSchema,
    onSubmit: async (values: PlanFormType) => {
      try {
        await onSubmit(values)
        onClose(true)
      } catch (err) {
        toast.error((err as Error).message || 'Something went wrong')
      }
    }
  })

  return (
    <Modal isOpen={true} ariaLabelledby='Campaign Modal' maxWidth='1024px' maxHeight='95vh'>
      {planFormik.isSubmitting && (
        <div className='fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-white bg-opacity-50'>
          <Loading />
        </div>
      )}
      <div className='flex w-full flex-col'>
        <Text className='heading-md '>Update pedagogical plan</Text>

        <hr className='mt-5' />
        <div className='max-h-full overflow-y-scroll px-2 py-5'>
          <div className='mb-4'>
            <label className='font-bold'>Week Name</label>
            <Input
              type='text'
              id='weekName'
              name='weekName'
              className='mt-2'
              placeholder=''
              onBlur={planFormik.handleBlur}
              value={planFormik.values.weekName}
              onChange={planFormik.handleChange}
              errorMessage={planFormik.submitCount > 0 ? planFormik.errors.weekName : undefined}
            />
          </div>

          <div className='mb-4'>
            <label className='font-bold'>Activity</label>
            <Input
              id='activity'
              name='activity'
              className='mt-2'
              placeholder=''
              onBlur={planFormik.handleBlur}
              value={planFormik.values.activity}
              onChange={planFormik.handleChange}
              errorMessage={planFormik.submitCount > 0 ? planFormik.errors.activity : undefined}
            />
          </div>

          <div className='mb-4'>
            <label className='font-bold'>Activity Tools</label>
            <Input
              type='text'
              id='activityTools'
              name='activityTools'
              className='mt-2'
              placeholder=''
              onBlur={planFormik.handleBlur}
              value={planFormik.values.activityTools}
              onChange={planFormik.handleChange}
              errorMessage={
                planFormik.submitCount > 0 ? planFormik.errors.activityTools : undefined
              }
            />
          </div>

          <div className='mb-4'>
            <label className='font-bold'>Assessment Rubric</label>
            <Input
              type='text'
              id='assessmentRubric'
              name='assessmentRubric'
              className='mt-2'
              placeholder=''
              onBlur={planFormik.handleBlur}
              value={planFormik.values.assessmentRubric}
              onChange={planFormik.handleChange}
              errorMessage={
                planFormik.submitCount > 0 ? planFormik.errors.assessmentRubric : undefined
              }
            />
          </div>

          <div className='mb-4'>
            <label className='font-bold'>Badge Earned</label>
            <Input
              type='text'
              id='badgeEarned'
              name='badgeEarned'
              className='mt-2'
              placeholder=''
              onBlur={planFormik.handleBlur}
              value={planFormik.values.badgeEarned}
              onChange={planFormik.handleChange}
              errorMessage={planFormik.submitCount > 0 ? planFormik.errors.badgeEarned : undefined}
            />
          </div>

          <div className='mb-4'>
            <label className='font-bold'>Mission Number</label>
            <Input
              type='text'
              id='missionNumber'
              name='missionNumber'
              className='mt-2'
              placeholder=''
              onBlur={planFormik.handleBlur}
              value={planFormik.values.missionNumber}
              onChange={planFormik.handleChange}
              errorMessage={
                planFormik.submitCount > 0 ? planFormik.errors.missionNumber : undefined
              }
            />
          </div>

          <div className='mb-4'>
            <label className='font-bold'>Skill Developed</label>
            <Input
              type='text'
              id='skillDeveloped'
              name='skillDeveloped'
              className='mt-2'
              placeholder=''
              onBlur={planFormik.handleBlur}
              value={planFormik.values.skillDeveloped}
              onChange={planFormik.handleChange}
              errorMessage={
                planFormik.submitCount > 0 ? planFormik.errors.skillDeveloped : undefined
              }
            />
          </div>

          <div className='mb-4'>
            <label className='font-bold'>Skill Power Level</label>
            <Input
              type='text'
              id='skillPowerLevel'
              name='skillPowerLevel'
              className='mt-2'
              placeholder=''
              onBlur={planFormik.handleBlur}
              value={planFormik.values.skillPowerLevel}
              onChange={planFormik.handleChange}
              errorMessage={
                planFormik.submitCount > 0 ? planFormik.errors.skillPowerLevel : undefined
              }
            />
          </div>
        </div>

        <hr className='mb-5' />

        <div className='ml-auto mt-auto flex w-full max-w-[300px] gap-4'>
          <Button
            label='Cancel'
            className='flex-1 bg-gray-500 hover:bg-gray-400'
            onClick={() => onClose(false)}
          />

          <Button
            label='Save'
            onClick={() => planFormik.submitForm()}
            className='flex-1 bg-brand-rama-500 hover:bg-brand-rama-400'
          />
        </div>
      </div>
    </Modal>
  )
}

export default EditPlanModal
