import {
  Button,
  Input,
  Loading,
  Modal,
  Select,
  SelectItem,
  Text,
  useToastContext
} from 'semente-js'
import { Campaign } from '../campaign-types'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import SkillService from '../../../services/skill.service'
import CampaignService from '../../../services/campaign.service'

type FormType = Omit<Campaign, 'campaign_skills'> & {
  campaign_skills: string[]
}

const initialValues: FormType = {
  act: '',
  goals: '',
  badges: '',
  imagine: '',
  explore: '',
  duration: '',
  frequency: '',
  communicate: '',
  how_will_use: '',
  devices_used: '',
  target_group: '',
  campaign_skills: [],
  grand_challenge: '',
  internet_access: ''
}

const validationSchema = Yup.object({
  act: Yup.string().required(),
  goals: Yup.string().required(),
  badges: Yup.string().required(),
  imagine: Yup.string().required(),
  explore: Yup.string().required(),
  campaign_skills: Yup.array().min(1),
  communicate: Yup.string().required(),
  devices_used: Yup.string().required(),
  target_group: Yup.string().required(),
  how_will_use: Yup.string().required(),
  grand_challenge: Yup.string().required(),
  internet_access: Yup.string().required(),
  duration: Yup.number().min(1).required(),
  frequency: Yup.number().min(1).required()
})

const HOW_WILL_USE = [
  {
    label: 'Part of Curriculum for a project-based learning assignment or in-school activity',
    value: 'Part of Curriculum for a project-based learning assignment or in-school activity'
  },
  {
    label: 'Extra-curricular as after school activity or competition',
    value: 'Extra-curricular as after school activity or competition'
  }
]

const EXPLORE = [
  { label: 'Strengths and Weaknesses of a team', value: 'Strengths and Weaknesses of a team' },
  { label: 'Clarity of team roles', value: 'Clarity of team roles' },
  { label: 'Community needs – community profile', value: 'Community needs – community profile' }
]

const IMAGINE = [
  {
    label: 'Problem Statement – a clear statement of the problem that the Evokation will address.',
    value: 'Problem Statement – a clear statement of the problem that the Evokation will address.'
  },
  {
    label: 'Vision Statement – a short statement on the vision for the Evokation',
    value: 'Vision Statement – a short statement on the vision for the Evokation'
  },
  {
    label:
      'World Changing Idea – information on how the solution will address that problem identified.',
    value:
      'World Changing Idea – information on how the solution will address that problem identified.'
  }
]

const ACT = [
  {
    label: 'Prototype – a minimally viable product on how the solution will work',
    value: 'Prototype – a minimally viable product on how the solution will work'
  },
  {
    label: 'Resources – Budget – financial resources required to execute Evokation',
    value: 'Resources – Budget – financial resources required to execute Evokation'
  },
  {
    label: 'Resources People – human resources required to execute the Evokation',
    value: 'Resources People – human resources required to execute the Evokation'
  }
]

const COMMUNICATE = [
  {
    label: 'Metrics – how will success be measured – a clear definition of your longer term goal',
    value: 'Metrics – how will success be measured – a clear definition of your longer term goal'
  },
  {
    label: 'Elevator Pitch – a short video communicating your Evokation',
    value: 'Elevator Pitch – a short video communicating your Evokation'
  }
]

const BADGES = [
  { label: 'Creative Visionary', value: 'Creative Visionary' },
  { label: 'Deep Collaborator', value: 'Deep Collaborator' },
  { label: 'Systems Thinker', value: 'Systems Thinker' },
  { label: 'Empathetic Activist', value: 'Empathetic Activist' }
]

const GOALS = [
  { label: 'Build 21st century skills', value: 'Build 21st century skills' },
  { label: 'Create Project or innovation', value: 'Create Project or innovation' }
]

const TARGET_GROUPS = [
  { label: 'University students', value: 'University students' },
  { label: 'High school students', value: 'High school students' },
  { label: 'Middle school students', value: 'Middle school students' },
  { label: 'Out of school youth', value: 'Out of school youth' },
  { label: 'Adult education students', value: 'Adult education students' }
]

const INTERNET_ACCESS = [
  { label: 'No connectivity', value: 'No connectivity' },
  { label: 'Some connectivity', value: 'Some connectivity' },
  {
    label: 'Limited connectivity (eg. for secretary only)',
    value: 'Limited connectivity (eg. for secretary only)'
  },
  {
    label: 'Limited connectivity (eg. secretary and compu',
    value: 'Limited connectivity (eg. secretary and compu'
  },
  { label: 'High speed broadband', value: 'High speed broadband' }
]

const DEVICES = [
  { label: 'No access to devices', value: 'No access to devices' },
  { label: 'Laptops', value: 'Laptops' },
  { label: 'Tablets', value: 'Tablets' },
  { label: 'Smartphones', value: 'Smartphones' },
  { label: 'Feature phones', value: 'Feature phones' },
  {
    label: 'Mixed devices (laptops, smartphones, tablets etc)',
    value: 'Mixed devices (laptops, smartphones, tablets etc)'
  }
]

const CHALLENGES = [
  {
    label: 'Quality Education – Leaders for Literacy',
    value: 'Quality Education – Leaders for Literacy'
  },
  { label: 'Reduced Inequalities – Migration', value: 'Reduced Inequalities – Migration' },
  { label: 'Sustainable Cities', value: 'Sustainable Cities' },
  {
    label: 'Responsible Consumption and Production',
    value: 'Responsible Consumption and Production'
  },
  { label: 'Climate Action – Climate Change', value: 'Climate Action – Climate Change' },
  {
    label: 'Peace, Justice and Strong Institutions',
    value: 'Peace, Justice and Strong Institutions'
  }
]

type Props = { isOpen: boolean; onClose: (refresh?: boolean) => void }

const CampaignModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { toast } = useToastContext()

  const [skills, setSkills] = useState<SelectItem[]>([])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: FormType) => {
      try {
        await CampaignService.createCampaign({
          ...values,
          campaign_skills: values.campaign_skills.map(skill_id => ({ skill_id }))
        })
      } catch (err) {
        toast.error((err as Error).message || 'Something went wrong')
      }
      onClose(true)
    }
  })

  useEffect(() => {
    if (isOpen) formik.resetForm()
    SkillService.getSkills().then(items => {
      setSkills(items.map(skill => ({ value: skill.id, label: skill.name })))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} ariaLabelledby='Campaign Modal' maxWidth='1024px' maxHeight='95vh'>
      {formik.isSubmitting && (
        <div className='fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-white bg-opacity-50'>
          <Loading />
        </div>
      )}

      <div className='flex w-full flex-col'>
        <Text className='heading-md '>New campaign</Text>

        <hr className='mt-5' />

        <div className='max-h-full overflow-y-scroll px-2 py-5'>
          <div className='mb-4'>
            <label className='font-bold'>Target group</label>
            <Select
              layout='line'
              isMultiple={false}
              items={TARGET_GROUPS}
              placeholder='Select one'
              onChange={value => {
                formik.setFieldValue('target_group', value[0])
              }}
              className={`mt-2 w-full ${formik.submitCount > 0 && formik.errors.target_group && 'border-[2px] border-red-600'}`}
            />
          </div>

          <div className='mb-4'>
            <label className='font-bold'>How will you use the campaign:</label>
            <Select
              layout='line'
              isMultiple={false}
              items={HOW_WILL_USE}
              placeholder='Select one'
              onChange={value => {
                formik.setFieldValue('how_will_use', value[0])
              }}
              className={`mt-2 w-full ${formik.submitCount > 0 && formik.errors.how_will_use && 'border-[2px] border-red-600'}`}
            />
          </div>

          <div className='mb-4'>
            <label className='font-bold'>Goals of Campaign:</label>
            <Select
              layout='line'
              isMultiple={false}
              items={GOALS}
              placeholder='Select one'
              onChange={value => {
                formik.setFieldValue('goals', value[0])
              }}
              className={`mt-2 w-full ${formik.submitCount > 0 && formik.errors.goals && 'border-[2px] border-red-600'}`}
            />
          </div>

          <div className='mb-4'>
            <label className='font-bold'>Internet Access</label>
            <Select
              layout='line'
              isMultiple={false}
              items={INTERNET_ACCESS}
              placeholder='Select one'
              onChange={value => {
                formik.setFieldValue('internet_access', value[0])
              }}
              className={`mt-2 w-full ${formik.submitCount > 0 && formik.errors.internet_access && 'border-[2px] border-red-600'}`}
            />
          </div>

          <div className='mb-4'>
            <label className='font-bold'>Devices that will be used</label>
            <Select
              layout='line'
              items={DEVICES}
              isMultiple={false}
              placeholder='Select one'
              onChange={value => {
                formik.setFieldValue('devices_used', value[0])
              }}
              className={`mt-2 w-full ${formik.submitCount > 0 && formik.errors.devices_used && 'border-[2px] border-red-600'}`}
            />
          </div>

          <div className='mb-4'>
            <label className='font-bold'>Expected duration of the campaign (in weeks)</label>
            <Input
              type='number'
              id='duration'
              name='duration'
              className='mt-2'
              placeholder=''
              onBlur={formik.handleBlur}
              value={formik.values.duration}
              onChange={formik.handleChange}
              errorMessage={formik.submitCount > 0 ? formik.errors.duration : undefined}
            />
          </div>

          <div className='mb-4'>
            <label className='font-bold'>
              Expected frequency that you expect youth to participate in this campaign (hours/week)
            </label>
            <Input
              type='number'
              id='frequency'
              name='frequency'
              className='mt-2'
              placeholder=''
              onBlur={formik.handleBlur}
              value={formik.values.frequency}
              onChange={formik.handleChange}
              errorMessage={formik.submitCount > 0 ? formik.errors.frequency : undefined}
            />
          </div>

          <div className='mb-4'>
            <label className='font-bold'>What Grand Challenge will you address</label>
            <Select
              layout='line'
              items={CHALLENGES}
              isMultiple={false}
              placeholder='Select one'
              onChange={value => {
                formik.setFieldValue('grand_challenge', value[0])
              }}
              className={`mt-2 w-full ${formik.submitCount > 0 && formik.errors.grand_challenge && 'border-[2px] border-red-600'}`}
            />
          </div>

          <div className='mb-4'>
            <label className='font-bold'>
              What badges do you wish to include in your campaign?
            </label>
            <Select
              layout='line'
              items={BADGES}
              isMultiple={false}
              placeholder='Select one'
              onChange={value => {
                formik.setFieldValue('badges', value[0])
              }}
              className={`mt-2 w-full ${formik.submitCount > 0 && formik.errors.badges && 'border-[2px] border-red-600'}`}
            />
          </div>

          <div className='mb-4'>
            <label className='font-bold'>
              What Skills do you wish to include in your campaign?
            </label>
            <Select
              layout='line'
              items={skills}
              isMultiple={true}
              value={formik.values.campaign_skills}
              placeholder='Skills'
              onChange={values => {
                formik.setFieldValue('campaign_skills', values)
              }}
              className={`mt-2 w-full ${formik.submitCount > 0 && formik.errors.campaign_skills && 'border-[2px] border-red-600'}`}
            />
          </div>

          <label className='mb-2 mt-10 flex w-full border-b-[1px] text-lg'>
            What final outputs would you like to see for each section of the Evokation
          </label>
          <div className='mb-4'>
            <label className='font-bold'>Engage and Explore</label>
            <Select
              layout='line'
              items={EXPLORE}
              isMultiple={false}
              placeholder='Select one'
              onChange={value => {
                formik.setFieldValue('explore', value[0])
              }}
              className={`mt-2 w-full ${formik.submitCount > 0 && formik.errors.explore && 'border-[2px] border-red-600'}`}
            />
          </div>

          <div className='mb-4'>
            <label className='font-bold'>Imagine</label>
            <Select
              layout='line'
              items={IMAGINE}
              isMultiple={false}
              placeholder='Select one'
              onChange={value => {
                formik.setFieldValue('imagine', value[0])
              }}
              className={`mt-2 w-full ${formik.submitCount > 0 && formik.errors.imagine && 'border-[2px] border-red-600'}`}
            />
          </div>

          <div className='mb-4'>
            <label className='font-bold'>Act</label>
            <Select
              layout='line'
              items={ACT}
              isMultiple={false}
              placeholder='Select one'
              onChange={value => {
                formik.setFieldValue('act', value[0])
              }}
              className={`mt-2 w-full ${formik.submitCount > 0 && formik.errors.act && 'border-[2px] border-red-600'}`}
            />
          </div>

          <div className='mb-20'>
            <label className='font-bold'>Communicate</label>
            <Select
              layout='line'
              items={COMMUNICATE}
              isMultiple={false}
              placeholder='Select one'
              onChange={value => {
                formik.setFieldValue('communicate', value[0])
              }}
              className={`mt-2 w-full ${formik.submitCount > 0 && formik.errors.communicate && 'border-[2px] border-red-600'}`}
            />
          </div>
        </div>

        <hr className='mb-5' />

        <div className='ml-auto mt-auto flex w-full max-w-[300px] gap-4'>
          <Button
            label='Cancel'
            className='flex-1 bg-gray-500 hover:bg-gray-400'
            onClick={() => onClose(false)}
          />

          <Button
            label='Save'
            onClick={() => formik.submitForm()}
            className='flex-1 bg-brand-rama-500 hover:bg-brand-rama-400'
          />
        </div>
      </div>
    </Modal>
  )
}

export default CampaignModal
